<script>
import CalendarApiService from "@/services/calendarService";
export default {
  model: {
    prop: "configurationId",
    event: "input",
  },
  props: ["configurationId", "editMode", "isMerged"],
  components: {},
  data() {
    return {
      configurations: [],
      configurationIsMerged: undefined,
    };
  },
  created() {
    this.configurations = [];
    var promiseMerged =
      CalendarApiService.getMergedProgressSchedulingUserList().then((result) =>
        this.configurations.push(...result.data.data)
      );
    var promise = CalendarApiService.getProgressSchedulingList().then(
      (result) => this.configurations.push(...result.data.data)
    );

    Promise.all([promiseMerged, promise]).then(() => {
      this.configurations.sort((a, b) =>
        this.compare(
          a.mergedProgress !== undefined ? a.name : a.progress.name,
          b.mergedProgress !== undefined ? b.name : b.progress.name
        )
      );
      this.configurations.forEach((x, index) => {
        x.index = index;
      })
    });
    this.configurationIsMerged = this.isMerged.$model ?? false;
  },
  updated() {
    this.configurationIsMerged = this.isMerged.$model ?? false;
  },
  computed: {
    configuration: {
      get() {
        if(this.configurationIsMerged) {
          return this.configurations?.find(
          (x) => x.id === this.configurationId.$model && x.mergedProgress !== undefined
        );
        }
        return this.configurations?.find(
          (x) => x.id === this.configurationId.$model && x.mergedProgress === undefined
        );
      },
      set(newValue) {
        this.configurationIsMerged = newValue.mergedProgress !== undefined;
        this.$emit("change-is-merged", this.configurationIsMerged);
        this.configurationId.$model = newValue.id;
      },
    },
  },
  methods: {
    compare(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
  },
  watch: {},
};
</script>
<template>
  <b-form-group>
    <label>{{ $t("general.configuration") }}</label>
    <div
      v-if="editMode && configurationId.$model != null && configuration != null"
    >
      <b-badge
        v-if="configuration.mergedProgress === undefined"
        :style="{
          background: configuration.progress.colorHexa,
        }"
        style="font-size: 1.3em"
        >{{ configuration.progress.name }}</b-badge
      >
      <b-badge
        v-if="configuration.mergedProgress !== undefined"
        :style="{
          background: configuration.mergedProgress[0].colorHexa,
        }"
        style="font-size: 1.3em"
        >{{ configuration.name }}</b-badge
      >
    </div>
    <multiselect
      v-if="!editMode"
      :validator="configurationId"
      :class="{
        'is-invalid': configurationId.$error,
      }"
      style="max-width: 300px"
      :placeholder="$t('general.chooseConfiguration')"
      v-model="configuration"
      :options="configurations"
      :show-labels="false"
      track-by="index"
      @open="$emit('open')"
      ><template slot="option" slot-scope="props"
        >{{
          props.option.mergedProgress !== undefined
            ? props.option.name
            : props.option.progress.name
        }}
      </template>
      <template slot="singleLabel" slot-scope="props">{{
        props.option.mergedProgress !== undefined
          ? props.option.name
          : props.option.progress.name
      }}</template></multiselect
    >

    <div v-if="configurationId.$error" class="invalid-feedback">
      <span v-if="!configurationId.required">{{
        $t("general.requiredValue")
      }}</span>
    </div>
  </b-form-group>
</template>