<script>
import { Sketch } from "vue-color";

export default {
  components: { Sketch },
  model: {
    prop: "color",
    event: "input",
  },
  props: ["color"],
  data() {
    return {
      showColorPicker: false,
    };
  },
  methods: {
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    updateColor(color) {
      this.color.$model = color.hex;
    },
    close() {
      this.showColorPicker = false;
    },
  },
};
</script>
<template>
  <b-form-group style="flex-direction: column">
    <label>{{ $t("general.color") }}</label>
    <div class="mb-2" style="display: flex; align-items: center">
      <div
        class="mr-2"
        :style="{ background: color.$model }"
        style="width: 27px; height: 27px; border-radius: 5px"
      ></div>
      <b-button
        @click="toggleColorPicker()"
        v-show="!showColorPicker"
        size="sm"
        variant="primary"
        >{{ $t("general.selectColor") }}</b-button
      >
      <b-button
        @click="toggleColorPicker()"
        v-show="showColorPicker"
        size="sm"
        variant="primary"
        >{{ $t("general.close") }}</b-button
      >
    </div>

    <Sketch
      v-show="showColorPicker"
      :value="color"
      @input="updateColor"
      style="position: absolute;"
    />
  </b-form-group>
</template>
