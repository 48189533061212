<script>
import MaintenanceForm from "./maintenance-form";
export default {
  components: { MaintenanceForm },
  data() {
    return {
      showModal: false,
      isLoading: false,
      confirmProgressPrioritizationMaintenance: null,
      editMode: undefined,
    };
  },
  beforeCreate() {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    openEditMaintenanceModal(maintenanceId, isMerged) {
      return new Promise((resolve) => {
        this.$refs.maintenanceForm.openMaintenance(maintenanceId, isMerged).then(() => {
          this.showModal = true;
        });
        this.editMode = 'edit';
        this.confirmProgressPrioritizationMaintenance = () => {
          this.isLoading = true;
          this.$refs.maintenanceForm
            .submitEdit()
            .then(() => {
              this.closeModal();
              resolve();
            })
            .finally(() => {
              this.isLoading = false;
            });
        };
      });
    },
    openCreateMaintenanceModal() {
      return new Promise((resolve) => {
        this.$refs.maintenanceForm.openCreateMaintenance();
        this.showModal = true;
        this.editMode = 'create';
        this.confirmProgressPrioritizationMaintenance = () => {
          this.isLoading = true;
          this.$refs.maintenanceForm
            .submitCreate()
            .then(() => {
              this.closeModal();
              resolve();
            })
            .finally(() => {
              this.isLoading = false;
            });
        };
      });
    },
    closeModal() {
      this.showModal = false;
      this.isLoading = false;
    },
  },
};
</script>
<template>
  <b-modal
    v-model="showModal"
    :title="editMode === 'create' ? $t('general.createMaintenancePlanification') : $t('general.editMaintenancePlanification')"
    title-class="text-black font-18"
    body-class="p-4"
    size="lg"
    no-close-on-backdrop
    static
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    >
    </loading>
    <MaintenanceForm ref="maintenanceForm" />
    <template #modal-footer class="text-right pt-3">
      <b-button variant="light" @click="closeModal">{{
        $t("general.close")
      }}</b-button>
      <b-button
        @click="confirmProgressPrioritizationMaintenance"
        :disabled="isLoading"
        class="ml-1"
        variant="success"
        >{{ $t("general.save") }}</b-button
      >
    </template>
  </b-modal>
</template>