<script>
import CalendarApiService from "@/services/calendarService";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import MaestroMenuLayout from "../components/maestro-menu-layout";
import MaintenanceModal from "./components/maintenance-modal";
import DeleteModal from "@/components/delete-modal";
export default {
  components: {
    PageHeader,
    MaestroMenuLayout,
    MaintenanceModal,
    DeleteModal,
  },
  page: {
    title: "maestro",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      maintenances: [],
      isLoading: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch("calendar/fetchProgress");
  },
  created() {
    this.loadMaintenances();
  },
  beforeDestroy() {},
  computed: {
    progress() {
      return this.$store.state.calendar.progress;
    },
  },
  methods: {
    loadMaintenances() {
      this.isLoading = true;
      this.maintenances = [];
      let mergedPromise = CalendarApiService.getMergedProgressMaintenances(
        true
      ).then((result) => {
        this.maintenances.push(...result.data);
      });
      let progressPromise = CalendarApiService.getProgressMaintenances(
        true
      ).then((result) => {
        this.maintenances.push(...result.data);
      });
      Promise.all([mergedPromise, progressPromise]).finally(() => {
        this.isLoading = false;
      });
    },
    getProgressByIds(ids) {
      return ids.map((id) => this.progress.find((p) => p.id === id));
    },
    openCreateMaintenance() {
      this.$refs.maintenanceModal.openCreateMaintenanceModal().then(() => {
        this.loadMaintenances();
      });
    },
    openEditMaintenance(id, isMerged) {
      this.$refs.maintenanceModal
        .openEditMaintenanceModal(id, isMerged)
        .then(() => {
          this.loadMaintenances();
        });
    },
    openDeleteMaintenanceModal(maintenance) {
      this.$refs.deleteMaintenanceModal
        .show(maintenance, maintenance.name)
        .then(
          (maintenance) => {
            let promise = maintenance.isMerged
              ? CalendarApiService.deleteMergedProgressMaintenance(
                  maintenance.id
                )
              : CalendarApiService.deleteProgressMaintenance(maintenance.id);
            promise.then(() => {
              var index = this.maintenances
                .map((m) => {
                  return m.id;
                })
                .indexOf(maintenance.id);
              this.maintenances.splice(index, 1);
            });
          },
          () => {}
        );
    },
  },
};
</script>
<template>
  <Layout :contentPadding="false">
    <MaestroMenuLayout ref="maestroMenu">
      <div style="padding: 24px">
        <div style="display: flex">
          <i
            @click="$refs.maestroMenu.toggleMenu()"
            style="cursor: pointer; font-size: 1.5em; margin-right: 8px"
            class="fa fa-fw fa-bars"
          ></i>
          <PageHeader :title="$t('general.maintenances')" />
        </div>
        <div class="card">
          <div class="card-body">
            <div zclass="row">
              <div czlass="col-12">
                <div style="display: flex" class="mb-3">
                  <p class="card-title-desc" style="margin-bottom: 0">
                    {{ $t("general.maintenancesConfig") }}
                  </p>
                  <b-button
                    style="margin-left: auto"
                    @click="openCreateMaintenance"
                    variant="primary"
                    >{{ $t("general.add") }}</b-button
                  >
                </div>

                <div class="table-responsive" style="position: relative">
                  <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    color="#f1b44c"
                    :width="70"
                    :height="70"
                    loader="dots"
                    :is-full-page="false"
                  >
                  </loading>
                  <table class="table table-hover table-centered">
                    <thead class="thead-light">
                      <tr>
                        <th class="">{{ $t("general.name") }}</th>
                        <th class="text-center">{{ $t("general.color") }}</th>
                        <th class="text-center">
                          {{ $t("general.configuration") }}
                        </th>
                        <th class="text-center">
                          {{ $t("general.type") }}
                        </th>
                        <th
                          class="text-center"
                          style="width: 100px; width: 200px"
                        >
                          {{ $t("general.action") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(maintenance, index) in maintenances"
                        :key="index"
                      >
                        <td>
                          {{ maintenance.name }}
                        </td>
                        <td>
                          <div
                            :style="{ background: maintenance.colorHexa }"
                            style="
                              margin: 0 auto;
                              width: 20px;
                              height: 20px;
                              border-radius: 5px;
                            "
                          ></div>
                        </td>
                        <td class="text-center">
                          <span
                            v-if="
                              progress &&
                              progress.length > 0 &&
                              maintenance.progressIds
                            "
                          >
                            <b-badge
                              v-for="(progress, index) in getProgressByIds(
                                maintenance.progressIds
                              )"
                              :key="index"
                              :style="{
                                background: progress.colorHexa,
                              }"
                              style="font-size: 1.1em"
                              class="mx-1"
                              >{{ progress.name }}</b-badge
                            ></span
                          >
                        </td>
                        <td class="text-center">
                          <span v-if="maintenance.measureType">{{
                            $t("general." + maintenance.measureType)
                          }}</span>
                        </td>
                        <td class="text-center">
                          <b-dropdown
                            dropleft
                            size="lg"
                            class="card-drop"
                            variant="link"
                            toggle-class="text-decoration-none p-0"
                            no-caret
                          >
                            <template #button-content>
                              <i class="fas fa-ellipsis-h" />
                            </template>

                            <b-dropdown-item
                              @click="
                                openEditMaintenance(
                                  maintenance.id,
                                  maintenance.isMerged
                                )
                              "
                            >
                              <i
                                class="fas fa-pencil-alt text-success mr-1"
                              ></i>
                              {{ $t("general.edit") }}
                            </b-dropdown-item>

                            <b-dropdown-item
                              @click="openDeleteMaintenanceModal(maintenance)"
                            >
                              <i class="fas fa-trash-alt text-danger mr-1"></i>
                              {{ $t("general.delete") }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MaestroMenuLayout>
    <MaintenanceModal ref="maintenanceModal" />
    <DeleteModal
      ref="deleteMaintenanceModal"
      :title="$t('general.deleteMaintenanceTitle')"
      :message="$t('general.areYouSureDeleteMaintenance')"
    />
  </Layout>
</template>