<script>
import CalendarApiService from "@/services/calendarService";
import { required, decimal } from "vuelidate/lib/validators";
import PrioritizationConfigurationSelector from "./components/prioritization-configuration-selector";
import ColorPicker from "@/components/color-picker";
export default {
  components: { PrioritizationConfigurationSelector, ColorPicker },
  data() {
    return {
      maintenance: undefined,
    };
  },
  beforeCreate() {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    openMaintenance(maintenanceId, isMerged) {
      this.$v.$reset();
      if (isMerged) {
        return CalendarApiService.getMergedProgressMaintenance(
          maintenanceId
        ).then((result) => {
          this.maintenance = result.data;
        });
      } else {
        return CalendarApiService.getProgressMaintenance(maintenanceId).then(
          (result) => {
            this.maintenance = result.data;
          }
        );
      }
    },
    openCreateMaintenance() {
      this.$v.$reset();
      this.maintenance = {
        name: null,
        measureType: undefined,
        configId: undefined,
        colorHexa: "#FFC25D",
        interval: undefined,
        isMerged: undefined
      };
    },
    submitCreate() {
      return this.save((model) => {
        return this.maintenance.isMerged
          ? CalendarApiService.createMergedProgressMaintenanceConfig(model)
          : CalendarApiService.createProgressMaintenanceConfig(model);
      });
    },
    submitEdit() {
      return this.save((model) => {
        return this.maintenance.isMerged
          ? CalendarApiService.editMergedProgressMaintenanceConfig(model)
          : CalendarApiService.editProgressMaintenanceConfig(model);
      });
    },
    save(fct) {
      return new Promise((resolve, reject) => {
        this.$v.maintenance.$touch();
        if (!this.$v.maintenance.$invalid) {
          var modelCopy = JSON.parse(
            JSON.stringify({
              id: this.maintenance.id,
              name: this.maintenance.name,
              measureType: this.maintenance.measureType,
              configId: this.maintenance.configId,
              colorHexa: this.maintenance.colorHexa,
              interval: parseFloat(this.maintenance.interval),
            })
          );
          fct(modelCopy).then(
            () => {
              resolve();
            },
            (error) => reject(error)
          );
        } else {
          reject();
        }
      });
    },
    closeMaintenanceColorPicker() {
      this.$refs.maintenanceColorPicker.close();
    },
  },
  validations: {
    maintenance: {
      name: { required },
      measureType: {},
      configId: { required },
      colorHexa: { required },
      interval: { required, decimal },
      isMerged:{}
    },
  },
};
</script>
<template>
  <b-form v-if="maintenance">
    <div class="row">
      <div class="col-sm-6 col-12">
        <b-form-group>
          <label>{{ $t("general.name") }}</label>
          <b-form-input
            :class="{
              'is-invalid': $v.maintenance.name.$error,
            }"
            v-model="$v.maintenance.name.$model"
            :placeholder="$t('general.enterName')"
          ></b-form-input>
          <div v-if="$v.maintenance.name.$error" class="invalid-feedback">
            <span v-if="!$v.maintenance.name.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-12">
        <PrioritizationConfigurationSelector
          v-model="$v.maintenance.configId"
          :isMerged="$v.maintenance.isMerged"
          :editMode="maintenance != undefined && maintenance.id != undefined"
          @change-is-merged="(merged) => ($v.maintenance.isMerged.$model = merged)"
          @open="closeMaintenanceColorPicker"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-12">
        <ColorPicker
          v-model="$v.maintenance.colorHexa"
          ref="maintenanceColorPicker"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-12">
        <b-form-group>
          <label>{{ $t("general.type") }}</label>
          <multiselect
            v-if="measureTypes && measureTypes.length > 0"
            :validator="$v.maintenance.measureType"
            :class="{
              'is-invalid': $v.maintenance.measureType.$error,
            }"
            style="max-width: 300px"
            :placeholder="$t('general.chooseAttributeDisplay')"
            v-model="maintenance.measureType"
            :options="measureTypes"
            :show-labels="false"
            @open="closeMaintenanceColorPicker"
          >
            <template slot="singleLabel" slot-scope="props">
              <span>{{ $t("general." + props.option) }}</span>
            </template>
            <template slot="option" slot-scope="props">
              <span>{{ $t("general." + props.option) }}</span>
            </template>
          </multiselect>
          <div
            v-if="$v.maintenance.measureType.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.maintenance.measureType.required">{{
              $t("general.requiredValue")
            }}</span>
          </div>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-12">
        <b-form-group>
          <label>{{ $t("general.interval") }}</label>
          <b-form-input
            :class="{
              'is-invalid': $v.maintenance.interval.$error,
            }"
            v-model="$v.maintenance.interval.$model"
            :placeholder="$t('general.chooseInterval')"
          ></b-form-input>
          <div v-if="$v.maintenance.interval.$error" class="invalid-feedback">
            <span v-if="!$v.maintenance.interval.required">{{
              $t("general.requiredValue")
            }}</span>
            <span v-if="!$v.maintenance.interval.decimal">{{
              $t("general.decimalRequired")
            }}</span>
          </div>
        </b-form-group>
      </div>
    </div>
  </b-form>
</template>